<template>
  <div :class="`select border border-gray-300 ${noBottomBorder ? 'no-bottom-border' : ''}`">
    <multiselect
      v-show="type === 'select' && !selectWithButtons"
      v-if="choices"
      v-model="value"
      label="value"
      track-by="value"
      :options="choices"
      :allowEmpty="false"
      selectLabel="Πάτα Enter για επιλογή"
      selectedLabel="Επιλεγμένο"
      deselectLabel=""
      placeholder="Επιλογή.."
      :searchable="false"
    >
      <template slot="singleLabel" slot-scope="{ option }"
        ><div class="flex justify-items-start items-center h-10 w-10">
          <div class="mr-4" v-if="option.image">
            <img :src="option.image" class="h-full inline-block" />
          </div>
          {{ title }}: <strong class="ml-1">{{ option.label }}</strong>
        </div></template
      >
      <template slot="option" slot-scope="{ option }"
        ><div class="flex justify-items-start items-center">
          <div class="h-10 w-10 mr-4">
            <img :src="option.image" v-if="option.image" class="h-full inline-block" />
          </div>
          <div class="option__desc">
            <span class="option__title">{{ option.label }}</span>
          </div>
        </div>
      </template>
    </multiselect>
    <div v-if="type === 'select' && selectWithButtons" class="p-2 text-base sm:my-0 sm:mx-auto">
      <span class="sm:block pb-1 select-button-label">{{ title }}</span>
      <div class="inline-block">
        <div
          v-for="choice in choices"
          :key="choice.value"
          :class="`ikonix-select-button inline-block m-1 cursor-pointer text-base px-4 ${
            value.value === choice.value ? 'selected' : ''
          }`"
          @click="selectValueByButton(choice)"
        >
          {{ choice.label }}
        </div>
      </div>
    </div>
    <div v-else-if="type === 'palette'" class="justify-start items-center flex px-4 h-full colorpick-container">
      <v-swatches
        v-model="color"
        :swatches="choices && choices.length > 0 ? choices : 'basic'"
        :show-fallback="!choices || !choices.length || !choices.length > 0"
        fallback-input-type="color"
        popover-x="left"
        fallback-ok-text="OK"
      >
        <span slot="trigger" :style="`background-color: ${color} `" class="color-box rounded-md"></span>
        <span slot="trigger" class="text-base ml-4 select-none">{{ title }}</span>
      </v-swatches>
    </div>
    <form
      :id="`my-form-${slug}`"
      class="form-input border-gray-300 bg-white flex justify-start items-center pl-2"
      v-else-if="type === 'text'"
      @submit.prevent
    >
      <div class="form-input-container py-2 flex sm:block justify-center items-center">
        <label class="text-base justify-center items-center" :for="slug"
          ><div class="label-text text-left sm:text-center inline-block sm:block">{{ title }}:</div></label
        >
        <input
          v-if="!attrs.textarea"
          type="text"
          class="m-2 ml-3 ml-1 text-sm focus:outline-none ikonix-input"
          style="min-width: 100px; flex: 1; max-width: 80%"
          @input="(e) => setSelectedDebounce(e.target.value, e)"
          :id="`${slug}`"
          :value="choice"
          placeholder="πχ Μαρία ή Γιώργος"
          :minlength="attrs.min"
          :maxlength="attrs.max"
          @keydown.stop=""
          required
        />
        <textarea
          class="m-2 ml-3 ml-1 text-sm focus:outline-none ikonix-input"
          @input="(e) => setSelectedDebounce(e.target.value, e)"
          :name="`${slug}`"
          :value="choice"
          style="flex: 1; min-width: 100px; max-width: 80%"
          placeholder="πχ Μαρία ή Γιώργος"
          :minlength="attrs.min"
          maxlength="60"
          @keydown.stop=""
          required
          v-else
        ></textarea>
      </div>
    </form>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import VSwatches from "vue-swatches";
import { colorPickTypeName } from "../assets/consts";
import emojiStrip from "emoji-strip";

export default {
  name: "ChoiceOptions",
  props: ["choices", "title", "slug", "setSelected", "choice", "noBottomBorder", "type", "attrs", "selectWithButtons"],
  components: { Multiselect, VSwatches },
  data: () => {
    return {
      value: "",
      color: "#000",
      timeout: null,
    };
  },
  watch: {
    value() {
      this.setSelected(this.value || null);
    },
    color() {
      this.setSelected(this.color);
    },
  },
  computed: {},
  mounted() {
    if (this.choice) this.value = this.getOption(this.choice);

    if (this.type === colorPickTypeName) this.color = this.choice;
  },
  methods: {
    selectValueByButton(choice) {
      this.value = {
        ...choice,
      };
    },
    getOption(value) {
      if (this.type !== colorPickTypeName && this.choices) return this.choices.find((choice) => choice.value === value);
      else return this.choice;
    },
    setSelectedDebounce(value, e) {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        if (e.target) {
          e.target.value = emojiStrip(value);
        }
        value = emojiStrip(value);
        this.setSelected(value);
      }, 500);
    },
  },
};
</script>

<style
  src="./../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>
<style src="./../../node_modules/vue-swatches/dist/vue-swatches.css"></style>

<style lang="scss">
.select {
  // flex-basis: 33.33333%;
  flex-basis: 25%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (min-width: 640px) {
    // max-width: 33.33333%;
    max-width: 25%;
  }

  @media screen and (max-width: 639px) {
    flex-basis: 100%;
  }
}

.form-input {
  width: 100%;
  flex: 1;

  .form-input-container {
    width: 100%;

    @media screen and (min-width: 640px) and (max-width: 890px) {
      display: block;
    }

    label {
      text-align: left;
    }
  }
}

.label-text {
  @media screen and (min-width: 1200px) {
    white-space: nowrap;
  }
}

.select-container {
  > * {
    @media screen and (max-width: 679px) {
      border-left: none !important;
      border-bottom: none !important;
    }
  }

  > * {
    &:nth-child(n + 4) {
      border-top: none;
    }

    // &:nth-child(1),
    // &:nth-child(2),
    // &:nth-child(3) {
    //   border-top: none !important;
    // }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      border-top: none !important;
    }

    // &:not(:last-child):not(:nth-child(3n + 3)) {
    //   border-right: none !important;
    // }

    &:not(:last-child):not(:nth-child(4n + 4)) {
      border-right: none !important;
    }

    // &:nth-child(3n + 3) {
    //   border-right: none !important;
    // }

    &:nth-child(4n + 4) {
      @media screen and (min-width: 640px) and (max-width: 1279px) {
        border-right: 1px solid rgb(209, 213, 219) !important;
      }
      border-right: none !important;
    }

    // &:last-child:not(:nth-child(3)):not(:nth-child(2)):not(:nth-child(1)):not(:nth-child(3n + 3)) {
    //   @media screen and (min-width: 640px) {
    //     flex-basis: calc(33.33333% + 1px);
    //   }
    // }

    &:last-child:not(:nth-child(4)):not(:nth-child(3)):not(:nth-child(2)):not(:nth-child(4n + 4)) {
      @media screen and (min-width: 640px) {
        flex-basis: calc(33.33333% + 1px);
      }
    }

    // &:nth-last-child(3),
    // &:nth-last-child(2),
    // &:nth-last-child(1) {
    //   @media screen and (min-width: 640px) {
    //     border-bottom: none;
    //   }
    // }

    @media screen and (min-width: 1280px) {
      &:nth-last-child(5) {
        border-bottom: none !important;
      }
    }

    &:nth-last-child(4),
    &:nth-last-child(3),
    &:nth-last-child(2),
    &:nth-last-child(1) {
      @media screen and (min-width: 640px) {
        border-bottom: none;
      }
    }

    &:nth-child(4):nth-last-child(2),
    &:nth-child(3):nth-last-child(3),
    &:nth-child(2):nth-last-child(4),
    &:nth-child(1):nth-last-child(5) {
      @media screen and (min-width: 640px) and (max-width: 1279px) {
        border-bottom: 1px solid rgb(209, 213, 219) !important;
      }
    }

    &:nth-child(4):nth-last-child(4),
    &:nth-child(4):nth-last-child(3),
    &:nth-child(3):nth-last-child(4),
    &:nth-child(6):nth-last-child(4),
    &:nth-child(7):nth-last-child(3),
    &:nth-child(7):nth-last-child(4) {
      border-bottom: 1px solid rgb(209, 213, 219) !important;
    }

    // &:nth-child(3):nth-last-child(3),
    // &:nth-child(3):nth-last-child(2),
    // &:nth-child(2):nth-last-child(3),
    // &:nth-child(5):nth-last-child(3),
    // &:nth-child(6):nth-last-child(2),
    // &:nth-child(6):nth-last-child(3) {
    //   border-bottom: 1px solid rgb(209, 213, 219) !important;
    // }
  }
}

.multiselect__tags {
  // border: 1px solid rgba(209, 213, 219);
  border: none;
  @media screen and (max-width: 639px) {
    border-right: none;
  }
  border-radius: 0;
  height: 60px;

  input {
    height: 100%;
    margin-top: -5px;
  }
}

.multiselect__select {
  top: 12px;
  -webkit-tap-highlight-color: transparent;
}

.multiselect__option--highlight {
  background: #ff6a6a;

  &:after {
    background: #ff6a6a;
  }
}

.flipbook-container {
  z-index: 2;
}

.multiselect--active .multiselect__tags {
  border-top: 1px solid rgba(209, 213, 219, 1);
  margin-top: -1px;
}

.multiselect__content-wrapper {
  border: 1px solid #d1d5db;
  margin-left: -1px;
  width: calc(100% + 2px);

  @media screen and (max-width: 639px) {
    width: calc(100% + 1px);
  }
}

.vue-swatches {
  height: 43px;
}

.vue-swatches__fallback__input {
  height: 36px;
  padding-right: 5px;
  padding-left: 5px;
}

.vue-swatches__fallback__button {
  padding: 3px 15px;
}

.vue-swatches__wrapper {
  margin-top: 2px;
  margin-bottom: -5px;
}

.vue-swatches__wrapper + .vue-swatches__fallback__wrapper {
  margin-top: 5px;
}

.color-box {
  min-width: 35px;
  min-height: 35px;
  display: inline-block;
  margin-right: 1px;
}

.vue-swatches__trigger__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  -webkit-tap-highlight-color: transparent;
}

.vue-swatches__swatch,
.vue-swatches__fallback__button {
  outline: none;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }
}

.colorpick-container {
  padding-top: 8.5px;
  padding-bottom: 8.5px;
}

.ikonix-input,
.ikonix-select-button {
  border: 1px solid rgba(31, 85, 114, 0.55);
  padding: 10px;
  border-radius: 7px;
}

.ikonix-select-button {
  // color: rgba(0, 0, 0, 0.6);
  opacity: 0.55;

  &.selected,
  &:hover {
    border-color: #2c7297;
    color: #333;
    opacity: 1;
  }
}

.form-input-container {
  label {
    @media screen and (max-width: 639px) {
      width: 90px;
      text-align: left;
    }
    @media screen and (max-width: 330px) {
      width: 70px;
    }
  }
}

.select-button-label {
  @media screen and (max-width: 639px) {
    width: 98px;
    @media screen and (max-width: 330px) {
      width: 78px;
    }
    text-align: left;
    display: inline-block;
  }
}
</style>
