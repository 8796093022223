import Vue from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";

import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCaretRight,
  faCaretLeft,
  faSearchPlus,
  faSearchMinus,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DomPortal from "vue-dom-portal";

library.add(faCaretRight, faCaretLeft, faSearchPlus, faSearchMinus, faAngleLeft, faAngleRight);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

if (window.location.hostname.includes("ikonix")) {
  // TODO CHANGE TO A STATEMENT THAT EQUALS WITH PRODUCTION HOSTNAME
  const env = "production";
  Sentry.init({
    dsn: "https://57d10fc2ff3441fb8fefc2fe42853334@o443673.ingest.sentry.io/5797280",
    environment: env,
    integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
  });
}
Vue.use(DomPortal);

// Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
