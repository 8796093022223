<template>
  <div id="app">
    <Renderer />
  </div>
</template>

<script>
import Renderer from "./components/Renderer.vue";
import smoothscroll from "smoothscroll-polyfill";

export default {
  name: "App",
  components: {
    Renderer,
  },
  data: () => {
    return {};
  },
  methods: {},
  mounted() {
    smoothscroll.polyfill();
  },
};
</script>

<style lang="scss">
html {
  max-width: 100vw;
  overflow-x: hidden;
}

#app {
  font-family: BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 20px;

  * {
    font-family: BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
      Segoe UI Symbol;
  }
}
</style>
