<template>
  <div class="renderer relative focus:outline-none" tabindex="0" @keydown.left="flipLeft" @keydown.right="flipRight">
    <div v-show="start">
      <div class="fixed top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-white z-10">
        <LoadingSpinner />
      </div>
    </div>
    <div class="text-center mt-4 sm:mt-2 mb-0.5">
      <div class="pl-4 w-28 sm:w-32 block mx-auto bg-white" v-if="config">
        <img :src="config.logoUrl || logo" alt="Myikona.gr" title="myikona.gr" class="block mx-auto mr-1" />
      </div>
    </div>
    <div
      v-if="config"
      :class="`pt-0 mx-2 sm:mx-8 sm:pt-4 rounded-md bg-white outer ${portrait ? 'portrait' : ''}`"
      :style="`border-color: ${config.baseColor}`"
    >
      <div class="flip-container">
        <flipbook
          class="flipbook pt-1 pb-1"
          ref="flipbook"
          :pages="pagesFiltered"
          :zooms="isMobile ? null : [1, 2]"
          :singlePage="singlePage"
          @flip-left-start="flipLeftStart"
          @flip-right-start="flipRightStart"
          @flip-left-end="flipEvent"
          @flip-right-end="flipEvent"
          :perspective="8000"
          :gloss="0.3"
          :ambient="0.3"
          :nPolygons="11"
          :startPage="1"
          :centering="false"
          :loadingImage="sampleImage"
          :clickToZoom="false"
          :dragToFlip="isMobile ? false : true"
        ></flipbook>

        <div class="controls flex justify-center items-center m-2">
          <span v-if="arrowsTarget" v-dom-portal="arrowsTarget" class="arrow-control left" @click="flipLeft"
            ><span class="arrow-container w-16 h-16 rounded-full flex justify-center items-center pr-2"
              ><font-awesome-icon
                :icon="['fas', 'angle-left']"
                :class="`mx-2
         ${page() !== 1 && !leftFlipOngoing ? 'text-white' : 'text-gray-300'}`"
                size="3x" /></span
          ></span>

          <!-- <span class="mx-2 text-2xl" v-if="page()">
            {{ !isMobile && page() !== 1 && page() !== numberOfPages ? `${page()}-${page() + 1}` : page() }} /
            {{ numberOfPages }}</span
          > -->

          <span v-if="arrowsTarget" v-dom-portal="arrowsTarget" class="arrow-control right" @click="flipRight"
            ><span class="arrow-container w-16 h-16 rounded-full flex justify-center items-center pl-2"
              ><font-awesome-icon
                :icon="['fas', 'angle-right']"
                :class="`mx-2 ${page()}
         ${
           ((isMobile && page() !== numberOfPages) || (!isMobile && page() !== numberOfPages - 1)) && !rightFlipOngoing
             ? 'text-white'
             : 'text-gray-300'
         }`"
                size="3x" /></span
          ></span>

          <font-awesome-icon
            v-if="!isMobile"
            :icon="['fas', 'search-minus']"
            :class="`mx-2 text-gray-600 ${!!canZoomOut() ? 'text-gray-600 cursor-pointer' : 'text-gray-300'}`"
            @click="zoomOut"
          />
          <font-awesome-icon
            v-if="!isMobile"
            :icon="['fas', 'search-plus']"
            :class="`mx-2 ${!!canZoomIn() ? 'text-gray-600 cursor-pointer' : 'text-gray-300'} `"
            @click="zoomIn"
          />
        </div>
      </div>
      <div class="choices-container" v-for="(person, personId) in config.data" :key="personId">
        <div class="flex flex-col sm:flex-row border-b border-t ikonix-border-gray">
          <!-- <div class="space ikonix-border-gray"></div> -->
          <div class="w-full mobile choices-container-inner">
            <div class="flex w-full flex-wrap xl:flex-nowrap flex-grow select-container ikonix-border-gray">
              <ChoiceOptions
                v-for="(choice, name) in person.choices"
                :key="`choices-${personId}-${name}`"
                :selectWithButtons="true"
                :choices="choice.options"
                :title="choice.label"
                :type="choice.type"
                :slug="name"
                :setSelected="(value) => setSelected(name, personId, value)"
                :choice="choice.value"
                :attrs="choice.attrs"
              />
              <div
                class="flex-grow p-2 mb-0 mt-0 bg-white space justify-center items-center flex ikonix-border-gray border-l border-r my-cta"
              >
                <!-- eslint-disable -->
                <button
                  class="px-4 py-4 rounded-md text-white focus:outline-none add-to-cart-btn uppercase font-semibold tracking-wider"
                  @click="addToCart"
                >
                  <!-- eslint-enable -->
                  Προσθηκη στο καλαθι
                </button>
              </div>
            </div>
          </div>

          <!-- <div class="space border-l ikonix-border-gray"></div> -->
        </div>

        <!-- <div class="mt-1 mb-1 m-4 ikonix-border-gray px-4 py-2 rounded-md">
          <h3 class="-mb-2">Επιλογές {{ getPersonId(personId) }}</h3>
          <div
            v-for="(choice, choiceName) in person.choices"
            :key="`person-${getPersonId(personId)}-${choiceName}`"
            class="max-w-64 inline-block mr-2"
          >
            <i class="text-base"
              ><b>{{ choice.label }}</b> -
              <span v-if="choice.value">{{ selectedChoice(choice, choice.value) }}</span>
            </i>
          </div>
        </div> -->
      </div>
    </div>

    <div class="images-container"></div>

    <div class="mx-auto p-2 italic text-sm mt-2">Σημείωση: Η χρήση emoji στα ελεύθερα πεδία δεν είναι δυνατή</div>
  </div>
</template>

<script>
import axios from "axios";
import Flipbook from "flipbook-vue";

import logo from "./../assets/logo.png";
import { debugConsole, isMobile, projectAlreadyPurchasedError } from "./../assets/general";
import { colorPickTypeName } from "./../assets/consts";
import LoadingSpinner from "./LoadingSpinner.vue";
import ChoiceOptions from "./ChoiceOptions.vue";

export default {
  name: "Renderer",
  props: [],
  data: () => {
    return {
      config: null,
      timeout: null,
      timeoutBorder: null,
      coverImage: null,
      pages: [null],
      pagesFiltered: [null],
      startPage: null,
      numberOfPages: null,
      logo,
      dirty: false,
      mobilePage: null,
      sampleImage: "https://www.myikona.gr/crm-images/new-loader.gif",
      sampleImageBlank: "https://www.myikona.gr/crm-images/white_blank.png",
      start: true,
      portrait: false,
      leftFlipOngoing: false,
      rightFlipOngoing: false,
      arrowsTarget: null,
    };
  },
  components: {
    ChoiceOptions,
    Flipbook,
    LoadingSpinner,
  },
  computed: {
    singlePage() {
      return false;
      // return window.location?.search.includes("singlepage=1") || isMobile();
    },
    isMobile() {
      return isMobile();
    },
  },
  watch: {
    mobilePage() {
      debugConsole.log("mobile page: ", this.mobilePage);

      let interval = setInterval(() => {
        const flipbook = document.querySelector(".flipbook");
        const flipContainer = document.querySelector(".flip-container");

        if (isMobile() && flipbook && flipContainer) {
          clearInterval(interval);
          if (this.mobilePage <= 3 || this.$refs?.flipbook?.page <= 3)
            setTimeout(() => {
              debugConsole.log("scrolling right-1!");
              // if (this.portrait) flipContainer.scrollLeft = 1 * window.innerWidth;
              // else flipbook.scrollLeft = 0.82 * window.innerWidth;
              if (this.portrait) flipContainer.scrollTo({ left: 0.82 * window.innerWidth, top: 0, behavior: "smooth" });
              else flipbook.scrollTo({ left: 0.82 * window.innerWidth, top: 0, behavior: "smooth" });
            }, 500);
          if (this.mobilePage % 2 == 1)
            setTimeout(() => {
              debugConsole.log("scrolling right-2!");
              // if (this.portrait) flipContainer.scrollLeft = 1 * window.innerWidth;
              // else flipbook.scrollLeft = 0.82 * window.innerWidth;
              if (this.portrait) flipContainer.scrollTo({ left: 0.82 * window.innerWidth, top: 0, behavior: "smooth" });
              else flipbook.scrollTo({ left: 0.82 * window.innerWidth, top: 0, behavior: "smooth" });
            }, 500);
          else
            setTimeout(() => {
              debugConsole.log("scrolling left!");
              if (this.portrait) flipContainer.scrollTo({ left: 20, top: 0, behavior: "smooth" });
              else flipbook.scrollTo({ left: 20, top: 0, behavior: "smooth" });
            }, 500);
        }
      }, 300);
    },
  },
  methods: {
    flipLeftStart() {
      this.leftFlipOngoing = true;
    },
    flipRightStart() {
      this.rightFlipOngoing = true;
    },
    flipLeft() {
      if (this.page() <= 0) return;
      if (isMobile()) {
        let myPage = this.page();
        if (myPage === 3) {
          this.mobilePage = 1;
        } else if (this.mobilePage === this.numberOfPages) this.mobilePage = this.numberOfPages - 2;
        else if (this.mobilePage % 2 == 0) this.mobilePage = this.$refs?.flipbook?.page - 1;
        else this.mobilePage -= 1;
        // debugConsole.log("my page :", myPage);
        // debugConsole.log("flipbook page: ", this.$refs?.flipbook?.page);
        // debugConsole.log("mobile page: ", this.mobilePage);
        if (myPage > 3 && myPage % 2 == 0) {
          this.$refs?.flipbook.flipLeft();
          // if (flipbook) flipbook.scrollLeft = 0 * window.innerWidth;
        } else if (this.mobilePage === this.numberOfPages - 2) this.$refs?.flipbook.flipLeft();
        else if (myPage > 3 && myPage % 2 == 1) {
          // if (flipbook) flipbook.scrollLeft = 0.82 * window.innerWidth;
        } else {
          this.$refs?.flipbook.flipLeft();
        }
      } else this.$refs?.flipbook.flipLeft();
    },
    flipRight() {
      if (this.page() >= this.numberOfPages) return;
      if (isMobile()) {
        let myPage = this.page();
        if (myPage === 1) {
          this.mobilePage = 3;
        } else if (myPage === this.numberOfPages - 2) {
          this.mobilePage = this.numberOfPages;
        } else if (this.mobilePage % 2 == 0) {
          this.mobilePage = this.$refs?.flipbook?.page + 1;
        } else this.mobilePage += 1;
        debugConsole.log("my page :", myPage);
        if (myPage >= 2 && myPage % 2 == 1) {
          this.$refs?.flipbook.flipRight();
          // if (flipbook) flipbook.scrollLeft = 0 * window.innerWidth;
        } else if (myPage === this.numberOfPages - 2) {
          this.$refs?.flipbook.flipRight();
        } else if (myPage >= 2 && myPage % 2 == 0) {
          // if (flipbook) flipbook.scrollLeft = 0.82 * window.innerWidth;
        } else {
          this.$refs?.flipbook.flipRight();
        }
      } else this.$refs?.flipbook.flipRight();
    },
    page() {
      return isMobile() && this.mobilePage ? this.mobilePage : this?.$refs?.flipbook?.page;
    },
    nextPage() {
      if (this.page() !== this.numberOfPages) return this.pagesFiltered[this.page() + 1];
    },
    selectedChoice(choice, value) {
      if (choice.options && choice.options.length > 0 && value && choice.type !== colorPickTypeName) {
        return choice.options.find((option) => option.value === value)?.label;
      }

      return value || "κενό";
    },
    getPersonId(id) {
      return id;
    },
    pagesToFetch(page) {
      debugConsole.log("pages to fetch - current page :", page);
      if (!page) return;

      if (page === 1 && this.start) {
        return [1, 2, 4];
      }
      if (page >= this.numberOfPages) return [this.numberOfPages - 2, this.numberOfPages - 1];
      else if (page % 2 === 1 && page !== 1) return [page - 1, page];
      else return [page, page + 1];
    },
    setSelected(choicesSlug, id, value) {
      // debugConsole.log(this.$refs.flipbook.page);
      debugConsole.log("set selected: ", choicesSlug, id, value);
      if (this?.$refs?.flipbook?.page) {
        const page = this.$refs.flipbook.page;

        const pages = this.pagesToFetch(page);
        this.debounceRequestPages(pages?.[0], pages?.[1], pages?.[2]); // TODO NEED TO FETCH PREV IMAGE IF PAGE % 2 ===0 BUT NEEDS TO CHANGE ELSEWHERE TOO, THIS IS ONLY FOR STARTUP
      }

      const choice = this.config?.data?.[id]?.choices?.[choicesSlug];

      if (choice?.value || choice?.value === "") {
        let valid = true;

        if (choice.options) {
          valid = false;
          // check if it is a valid option for select type choice
          Object.keys(choice.options).forEach((key) => {
            if (value?.value && value.value === choice.options[key].value) valid = true;
            else if (value === choice.options[key].value) valid = true;
          });
        }

        if (valid) {
          this.dirty = true;
          this.config.data[id].choices[choicesSlug].value = value?.value || value;
        } else {
          const myValue = value?.value ? value.value : value;
          const text = "Invalid option: " + myValue + " for choice: " + choicesSlug;
          alert(text);
        }
      } else {
        alert("config data object not found!!@@!!");
        debugConsole.log(choicesSlug, id, value);
        debugConsole.log(this.config);
      }
    },
    getConfig(uid, queryStrings) {
      if (!uid) return;

      axios
        .get(process.env.VUE_APP_API + "/ikonix-project/read/" + uid)
        .then((response) => {
          if (response.data) {
            if (response.data.previewPage) {
              // detect if portrait or landscape
              if (this.start && response.data.previewPage) {
                var img = new Image();
                // debugConsole.log("orientation");

                img.onload = () => {
                  debugConsole.log("load");
                  // debugConsole.log("portrait: ", img.naturalWidth, img.naturalHeight);
                  if (img.naturalWidth > img.naturalHeight) {
                    this.portrait = false;

                    setTimeout(() => {
                      const flipbook = document.querySelector(".flipbook");
                      if (flipbook) {
                        flipbook.style.minWidth = "10px";
                        flipbook.scrollTo({ left: 500, top: 0, behavior: "smooth" });
                      }
                      // LANDSCAPE HACK (ADD A BIG MAX WIDTH IN THE BEGINNING AND REMOVE IT AFTER)
                    }, 1000);
                  } else if (img.naturalWidth < img.naturalHeight) {
                    this.portrait = true;
                    setTimeout(() => {
                      const flipContainer = document.querySelector(".flip-container");
                      if (flipContainer)
                        flipContainer.scrollTo({ left: 0.8 * window.innerWidth, top: 0, behavior: "smooth" });
                    }, 1000);
                  } else {
                    // orientation = "even";
                  }

                  Object.keys(response.data.data).forEach((personKey) => {
                    let person = response.data.data[personKey];
                    Object.keys(person.choices).forEach((choiceKey) => {
                      let choice = person.choices[choiceKey];
                      // debugConsole.log(choice);
                      let myChoices = [];
                      if (choice.options && choice.type !== colorPickTypeName) {
                        Object.keys(choice.options).forEach((key) =>
                          myChoices.push({
                            value: key,
                            label: choice.options[key],
                          })
                        );
                        choice.options = myChoices;

                        // if (response.data.stylesheets)
                        //   response.data.stylesheets?.forEach((stylesheet) => {
                        //     addCSSExternalLink(stylesheet);
                        //   });
                      }
                    });
                  });

                  this.config = response.data;

                  this.setDefaultsFromQueryStrings(queryStrings);
                };
                const container = document.querySelector(".images-container");
                img.src = response.data.previewPage;
                if (container)
                  container.innerHTML +=
                    '<img src="' +
                    img.src +
                    '" id="orientation-img" style="visibility: hidden; opacity: 0; height: 0px;" />';
              }
            }
          }
        })
        .catch((e) => {
          debugConsole.log(e);
          projectAlreadyPurchasedError(e.response.data, e?.response?.data?.domain);
        });
    },
    setDefaultsFromQueryStrings(queryStrings) {
      if (queryStrings.defaults) {
        Object.keys(queryStrings.defaults).forEach((key) => {
          const arr = key.split("_");
          const personId = arr[0];
          const name = arr[1];
          const value = queryStrings.defaults[key];

          this.setSelected(name, personId, value);
        });
      }
    },
    myFormValidity(ind) {
      const form = document.querySelector(`form#my-form-person${ind + 1}`);
      if (form) {
        return form.reportValidity();
      }
      return true;
    },
    flipEvent(nextPage) {
      debugConsole.log("next page: ", nextPage);
      this.rightFlipOngoing = false;
      this.leftFlipOngoing = false;

      if (isMobile()) {
        this.mobilePage = nextPage;
        if (nextPage == 2) {
          this.mobilePage = 3;
        } else if (nextPage === this.mobilePage - 1) {
          // nextPage = this.mobilePage;
        } else if (nextPage === this.numberOfPages - 1) {
          nextPage = this.numberOfPages - 2;
        }
      }

      const page = nextPage;
      if (page) {
        const pages = this.pagesToFetch(page);

        this.debounceRequestPages(pages?.[0], pages?.[1]);
      }
    },
    debounceRequestPages(...pages) {
      debugConsole.log("debounce request pages");

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.requestPages(...pages);
      }, 200);
    },
    requestPages(...pages) {
      let initial = false;
      let nextPagesInitial = false,
        prevPagesInitial = false;
      pages.forEach((page) => {
        if (this.pagesFiltered[page] === this.sampleImage) initial = true;
        if (this.pagesFiltered?.[page + 1] === this.sampleImage || this.pagesFiltered?.[page + 2] === this.sampleImage)
          nextPagesInitial = true;

        if (this.pagesFiltered?.[page - 1] === this.sampleImage || this.pagesFiltered?.[page - 2] === this.sampleImage)
          prevPagesInitial = true;
      });
      debugConsole.log(prevPagesInitial);

      // debugConsole.log("dirty: ", this.dirty, "initial", initial, "nextpagesInitial : ", nextPagesInitial);
      if (!this.dirty && !initial && !nextPagesInitial && !prevPagesInitial) return;
      // debugConsole.log("hi");

      let pagesQueryString = "";
      pages.forEach((page) => {
        if (page <= this.config.numberOfPages + 1) {
          if (pagesQueryString === "") pagesQueryString += "?";
          else pagesQueryString += "&";
          pagesQueryString += `pages[]=${page > 2 ? page - 1 : page}`;
        }
      });

      let form = new FormData();

      let myData = JSON.parse(JSON.stringify(this.config.data));

      Object.keys(myData).forEach((personKey) => {
        let person = myData[personKey];
        Object.keys(person.choices).forEach((choiceKey) => {
          let choice = person.choices[choiceKey];
          if (choice.type === "select") {
            let myChoices = {};
            choice.options.forEach((option) => {
              myChoices[option.value] = option.label;
            });
            choice.options = myChoices;
          }
        });
      });

      form.append("data", JSON.stringify(myData));

      if (nextPagesInitial) {
        this.secondRequestPages(pages, myData);
      }
      if (prevPagesInitial) {
        this.secondRequestPages(pages, myData, true);
      }

      debugConsole.log("next or prev and not dirty", nextPagesInitial, prevPagesInitial, !this.dirty);
      // if ((nextPagesInitial || prevPagesInitial) && !this.dirty) {
      //   return;
      // }

      axios
        .post(`${process.env.VUE_APP_API}/ikonix-project/preview/${this.config.uid}${pagesQueryString}`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // debugConsole.log(response.data);
          if (response.data) {
            this.changeImages(response.data);
            if (!nextPagesInitial) this.secondRequestPages(pages, myData);
          }
        })
        .catch((e) => {
          debugConsole.log(e);
          projectAlreadyPurchasedError(e.response.data, this.config.domain);
        });
    },
    secondRequestPages(pages, myData, prev = false) {
      debugConsole.log("secondRequestPages");
      debugConsole.log(pages);
      let pagesQueryString = "";
      if (!prev)
        pages.forEach((page) => {
          if (page + 2 <= this.numberOfPages - 1) {
            if (pagesQueryString === "") pagesQueryString += "?";
            else pagesQueryString += "&";
            pagesQueryString += `pages[]=${page + 1}`;
          }
        });
      else
        pages.forEach((page) => {
          debugConsole.log("else");
          if (page - 2 >= 0) {
            if (pagesQueryString === "") pagesQueryString += "?";
            else pagesQueryString += "&";
            pagesQueryString += `pages[]=${page - 3}`;
          }
        });

      debugConsole.log(pagesQueryString);

      let form = new FormData();
      form.append("data", JSON.stringify(myData));

      axios
        .post(`${process.env.VUE_APP_API}/ikonix-project/preview/${this.config.uid}${pagesQueryString}`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // debugConsole.log(response.data);
          if (response.data) {
            this.changeImages(response.data);
          }
        })
        .catch((e) => {
          debugConsole.log(e);
          projectAlreadyPurchasedError(e.response.data, this.config.domain);
        });
    },
    changeImages(data) {
      debugConsole.log("change images: ", data);

      this.pages.forEach((image, index) => {
        if (index !== 0 && this.dirty) {
          if (!Object.keys(data).includes(index.toString())) this.pages[index] = this.sampleImage;
        }
      });
      this.dirty = false;
      let firstTime = false;

      Object.keys(data).forEach((key) => {
        // if (this.pages[Number(key)] !== data[key]) change = true;
        const image = document.querySelector(`img[src="${data[key]}"]`) || document.createElement("img");
        if (!document.querySelector(`img[src="${data[key]}"]`)) {
          image.src = data[key];
          image.height = 0;
          image.width = 0;
          image.style.visibility = "hidden";
          image.style.opacity = 0;
          document.body?.appendChild(image);
        }

        key = Number(key);
        const newKey = key;
        // debugConsole.log("change images, assigning image: ", data[key], " to key: ", newKey);

        if (key === 1 && this.start && data[3]) {
          debugConsole.log("is complete: ", image.complete);
          if (!image.complete) {
            const imagePreview = document.querySelector(`img[src="${this.config.previewPage}"]`);
            if (imagePreview.complete) {
              debugConsole.log("preview image is complete, using that one temporarily");
              this.pages[1] = this.config.previewPage;
              firstTime = true;
            }
            image.onload = () => {
              debugConsole.log("onload");
              this.pages[1] = data[3];
            };
            image.onerror = (data) => {
              console.log(data);
            };
          } else {
            this.pages[1] = data[3];
            firstTime = true;
          }
        } else {
          if (this.pages[newKey] !== data[key]) this.pages[newKey] = data[key];
          else debugConsole.log("not changing image cause it's the same: ", data[key], " keys: ", newKey, " ", key);
        }
        this.firstTime(data, firstTime);
      });
    },
    firstTime(data, firstTime) {
      this.pagesFiltered = this.pages.slice(0, this.config.numberOfPages + 1);
      this.pagesFiltered.splice(2, 0, this.sampleImageBlank);
      this.pagesFiltered.splice(this.config.numberOfPages + 1, 0, this.sampleImageBlank);
      this.pagesFiltered[0] = null;

      if (firstTime) {
        debugConsole.log("firsttime");

        this.coverImage = data?.[1];
        this.start = false;
        setTimeout(() => {
          this.changeImages(data);
          const boundingBox = document.querySelector(".bounding-box");
          if (boundingBox) {
            boundingBox.style.display = "block";
          }
        }, 500);
        setTimeout(() => {
          const container = document.querySelector(".flip-container");
          if (container) container.className += " done";
        }, 600);
      }
    },
    addToCart() {
      const inputs = document.querySelectorAll("input");
      let valid = true;
      inputs.forEach((input) => {
        if (!input.reportValidity()) valid = false;
      });

      debugConsole.log(valid ? "valid!" : "invalid!");

      if (valid) {
        let noIframe = true; // toggle iframe mode
        const params = new URLSearchParams(window.location.search);
        if (params && params.get("no-frame") && params.get("no-frame") > 0) {
          noIframe = true;
        }

        if (noIframe) {
          const form = document.createElement("form");
          form.action = this.config?.addToCartUrl;
          form.method = "post";
          const input = document.createElement("input");
          input.type = "hidden";
          input.id = "data";
          input.name = "data";
          input.value = JSON.stringify(this.config);
          form.appendChild(input);
          if (form && input) {
            document.body.appendChild(form);
            debugConsole.log(form);
            form.submit();
          }
        } else {
          if (window.parent) window.parent.postMessage({ config: this.config }, this.config.domain);
        }
      }

      return valid;
    },
    debounceBorderImage() {
      // debugConsole.log('debounce request pages')
      if (this.timeoutBorder) clearTimeout(this.timeoutBorder);

      this.timeoutBorder = setTimeout(() => {
        this.borderImage();
      }, 200);
    },
    borderImage() {
      if (
        this.$refs?.flipbook?.page &&
        this.$refs?.flipbook?.page !== 1 &&
        this.$refs?.flipbook?.page !== this.numberOfPages
      ) {
        debugConsole.log("borderImage observer, page is: ", this.$refs?.flipbook?.page);
        const outerRight = this.$refs?.["outer-right"];
        // debugConsole.log(outerRight);
        const boundingBox = document.querySelector(".bounding-box");
        boundingBox.style.borderImage = `url(${this.coverImage}) 10/1/3px stretch`;
        boundingBox.style.borderWidth = "3px";
        boundingBox.style.position = "relative";

        if (outerRight && isMobile()) {
          let top = parseInt(boundingBox.style.top.replace("px", "")) + 4;

          outerRight.style.marginTop = top + "px";
          outerRight.style.height = boundingBox.style.height;
        }
      } else {
        this.hideBorderImage();
      }
    },
    hideBorderImage() {
      // debugConsole.log("hideBorderImage function");
      const boundingBox = document.querySelector(".bounding-box");
      boundingBox.style.borderImage = `url(https://www.transparenttextures.com/patterns/asfalt-light.png) 10/1/3px stretch`;
    },
    zoomIn() {
      this.$refs?.flipbook?.zoomIn();
    },
    zoomOut() {
      this.$refs?.flipbook?.zoomOut();
    },
    canZoomIn() {
      return true;
    },
    canZoomOut() {
      return true;
    },
    getQueryParams(query) {
      let params = {};
      new URLSearchParams(query).forEach((value, key) => {
        let decodedKey = decodeURIComponent(key);
        let decodedValue = decodeURIComponent(value);
        // This key is part of an array
        if (decodedKey.endsWith("[]")) {
          decodedKey = decodedKey.replace("[]", "");
          params[decodedKey] || (params[decodedKey] = []);

          let myObj = {};
          if (decodedValue.includes(":")) {
            params[decodedKey] = Object.keys(params[decodedKey]).length > 0 ? params[decodedKey] : {};
            let key_val = decodedValue.split(":");
            myObj[key_val[0].trim()] = key_val[1].trim();
          }

          if (Object.keys(myObj).length > 0) {
            Object.keys(myObj).forEach((key) => {
              params[decodedKey][key] = myObj[key];
            });
          } else {
            params[decodedKey].push(decodedValue);
          }

          // Just a regular parameter
        } else {
          params[decodedKey] = decodedValue;
        }
      });

      return params;
    },
  },

  mounted() {
    let queryStrings = location.search.substring(1);
    queryStrings = this.getQueryParams(queryStrings);

    debugConsole.log(queryStrings);

    if (queryStrings.uid) {
      const matches = queryStrings.uid.split("-"); // get pages number from uid
      // create empty pages according to num of total pages
      this.numberOfPages = Number(matches[2]) + 2;
      if (this.numberOfPages)
        for (let i = 0; i < Number(this.numberOfPages) + 2; i++) {
          this.pages.push(this.sampleImage);
        }
    }

    this.getConfig(queryStrings.uid, queryStrings);

    let j = 0;

    if (isMobile()) {
      let i = 0;
      let interval = setInterval(() => {
        const flipbook = document.querySelector(".flipbook");
        const flipContainer = document.querySelector(".flip-container");

        if (this.portrait) {
          if (flipContainer || i > 100) {
            if (flipContainer) {
              flipContainer.scrollTo({ left: 0.82 * window.innerWidth, top: 0, behavior: "smooth" });
              flipContainer.addEventListener("touchend", () => {
                debugConsole.log("touchend");
                const scrollLeft = flipContainer.scrollLeft;
                if (scrollLeft >= 0.6 * window.innerWidth && this.mobilePage % 2 === 0) {
                  this.mobilePage += 1;
                } else if (scrollLeft < 0.6 * window.innerWidth && this.mobilePage % 2 === 1) {
                  this.mobilePage -= 1;
                }
              });
            }
            if (i > 100) debugConsole.log("couldnt find flipbook");
            clearInterval(interval);
          }
        } else {
          if (flipbook || i > 100) {
            if (flipbook) {
              flipbook.scrollTo({ left: 0.82 * window.innerWidth, top: 0, behavior: "smooth" });
              flipbook.addEventListener("touchend", () => {
                debugConsole.log("touchend");
                const scrollLeft = flipbook.scrollLeft;
                if (scrollLeft >= 0.15 * window.innerWidth && this.mobilePage % 2 === 0) {
                  this.mobilePage += 1;
                } else if (scrollLeft < 0.15 * window.innerWidth && this.mobilePage % 2 === 1) {
                  this.mobilePage -= 1;
                }
              });
              debugConsole.log(flipbook.onScroll);
            }
            if (i > 100) debugConsole.log("couldnt find flipbook");
            clearInterval(interval);
          }
        }
      }, 200);
    }

    let observer = new MutationObserver((mutationsList) => {
      for (var mutation of mutationsList) {
        if (mutation.attributeName === "style") {
          this.debounceBorderImage();
          setTimeout(() => {
            this.debounceBorderImage();
          }, 50);
        }
      }
    });

    let intervalB = setInterval(() => {
      const boundingBox = document.querySelector(".bounding-box");
      if (boundingBox) {
        this.arrowsTarget = ".flipbook";

        observer.observe(boundingBox, {
          attributes: true,
        });
        clearInterval(intervalB);
      } else if (j > 100) clearInterval(intervalB);
    }, 200);
  },
};
</script>

<style lang="scss">
@import url("https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css");
.wizard-tab-container {
  display: block;
  animation: fadeInRight 0.3s;
}
.choices-container {
  flex-wrap: wrap;
  box-shadow: 0px -15px 4px 1px rgba(244, 244, 239, 0.78);

  @media screen and (max-width: 639px) {
    flex-direction: column !important;
  }
  @media screen and (min-width: 640px) {
    margin-top: 30px;
  }
}

.vue-form-wizard .wizard-tab-content {
  @media screen and (max-width: 450px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.renderer {
  // max-width: 100vw;
  max-width: 1700px;
  margin: 0 auto;
  // overflow: hidden;
}

.flipbook {
  width: 100%;
  min-height: 550px;
  height: 28vw;
  scroll-behavior: smooth;
  @media screen and (min-width: 599px) {
    position: relative;
  }

  @media screen and (max-width: 684px) {
    min-height: 250px;
    min-width: 650px;

    overflow-x: scroll;
    overflow-y: hidden;
  }

  @media screen and (min-width: 640px) and (max-width: 1000px) {
    height: 21vw;
    min-height: 500px;
  }
  box-sizing: content-box;
}

.portrait {
  .flipbook {
    @media screen and (max-width: 639px) {
      min-height: 500px;
      // padding-bottom: 30px;
    }
  }
}

.form-input {
  flex-basis: 33.33333%;

  @media screen and (max-width: 684px) {
    min-width: 100%;
  }
}

@media screen and (max-width: 684px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 12px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  .flipbook-container {
    transform: scale(1) !important;
    transform-origin: center !important;
    // margin-top: -25%;
  }

  .portrait {
    .flipbook {
      min-width: 680px;

      .viewport {
        overflow: hidden;
      }
    }

    .flip-container {
      overflow-x: scroll;
      scroll-behavior: smooth;
    }

    .flipbook-container {
      margin-left: 3px;
      // margin-top: 3%;
      transform: scale(0.95) !important;
      // transform: none !important;
      transform-origin: center !important;
      width: 100%;
    }

    .controls {
      // position: absolute;
      // z-index: 2;
      // transform: translate(-50%, -50%);
      // left: 50%;
      // top: 560px;
      display: none;
    }
  }
}

.portrait {
  @media screen and (max-width: 405px) {
    .flip-container {
      overflow-y: hidden;
    }
  }

  .flipbook {
    @media screen and (max-width: 405px) {
      padding-bottom: 0 !important;
      margin-bottom: -30px;
    }
    @media screen and (max-width: 370px) {
      margin-bottom: -40px;
    }

    @media screen and (max-width: 350px) {
      margin-bottom: -70px;
    }

    @media screen and (max-width: 330px) {
      margin-bottom: -110px;
    }

    .flipbook-container {
      @media screen and (max-width: 405px) {
        transform: scale(0.9) !important;
        margin-top: -14px;
      }

      @media screen and (max-width: 370px) {
        margin-left: -15px;
        margin-top: -20px;
        transform: scale(0.85) !important;
      }

      @media screen and (max-width: 350px) {
        margin-left: -35px;
        margin-top: -35px;
        transform: scale(0.8) !important;
      }

      @media screen and (max-width: 330px) {
        margin-left: -60px;
        margin-top: -60px;
        transform: scale(0.7) !important;
      }
    }
  }
}

.controls {
  white-space: nowrap;
}

.arrow-container {
  background: #1f5572;
}

.space {
  flex-basis: 10%;

  @media screen and (max-width: 799px) {
    display: none;
  }
}

.add-to-cart-btn {
  background-image: linear-gradient(90deg, #ec1d23, #ec1d6b, #ec1d6b, #ec1d23) !important;
}

.bounding-box {
  box-shadow: 7px 7px 5px -5px RGB(0 0 0 / 63%);
}

.flip-container {
  opacity: 0;
  max-height: 500px;

  &.done {
    opacity: 1;
    max-height: none;
  }
}

.portrait img[src="https://www.myikona.gr/crm-images/new-loader.gif"]
{
  max-height: 333px !important;
  top: 50% !important;
  right: 80%;
  transform: translate(-80%, -50%) scale(0.3) !important;

  + div .polygon {
    background-image: none !important;
    background-color: #ccc !important;
  }
}

.img[src="https://www.myikona.gr/crm-images/new-loader.gif"]
{
  max-height: 333px !important;
  top: 50% !important;
  transform: translate(0, -50%) scale(0.3) !important;

  + div .polygon {
    background-image: none !important;
    background-color: #ccc !important;
  }
}

@media screen and (min-width: 685px) {
  img[src="https://www.myikona.gr/crm-images/new-loader.gif"]
  {
    max-height: 250px !important;
    max-width: 300px;
    top: 63% !important;
    left: 78% !important;
    transform: translate(-50%, -63%) scale(0.3) !important;

    + div .polygon {
      background-image: none !important;
      background-color: #ccc !important;
    }
  }
}

div .polygon {
  background-color: #ccc !important;
}

img[src="https://www.myikona.gr/crm-images/loading-spinner-transparent.gif"]
{
  max-height: 200px !important;
  max-width: 200px !important;
  top: 30% !important;
  left: 30% !important;
}

img[src="https://www.myikona.gr/crm-images/loading-spinner-transparent.gif"] + img[src="https://www.myikona.gr/crm-images/loading-spinner-transparent.gif"]
{
  left: 56% !important;
}

.arrow-control {
  position: absolute;
  top: 250px;
  bottom: unset;
  z-index: 2;
  width: 90px;
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  @media screen and (max-width: 600px) {
    // position: fixed;
    bottom: initial;
    top: 125px;
  }

  &.left {
    left: 0;
    justify-content: flex-start;
    padding-left: 20px;

    @media screen and (max-width: 600px) {
      padding-left: 5px;
    }
  }
  &.right {
    right: 0;
    justify-content: flex-end;
    padding-right: 20px;

    @media screen and (max-width: 600px) {
      padding-right: 5px;
    }
  }
}

.portrait {
  .arrow-control {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 200px;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    @media screen and (max-width: 600px) {
      // position: fixed;
      bottom: initial;
      top: 235px;
    }

    &.left {
      left: 0;
      justify-content: flex-start;
      padding-left: 20px;

      @media screen and (max-width: 600px) {
        padding-left: 5px;
      }
    }
    &.right {
      right: 0;
      justify-content: flex-end;
      padding-right: 20px;

      @media screen and (max-width: 600px) {
        padding-right: 5px;
      }
    }
  }
}

.ikonix-border-gray {
  border-color: #e4e1d9;
}

.my-cta {
  @media screen and (max-width: 639px) {
    border: none !important;
  }
}
</style>
